.container {
  @apply flex overflow-hidden space-x-6 md:space-x-12 absolute top-0 left-0 h-28 flex items-center px-6;
  animation: bannermove;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-duration: 60s;
  animation-timing-function: linear;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
